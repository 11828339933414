/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
export default [
  {
    url: "/overview",
    name: "menu.overview",
    slug: "overview",
    icon: "BookOpenIcon"
  },
  {
    url: "/researches",
    name: "menu.researches",
    slug: "researches",
    icon: "LayersIcon"
  },
  {
    url: "/account",
    name: "menu.account",
    slug: "account",
    icon: "UserIcon"
  },
  {
    url: "/plan-code-activate",
    name: "menu.plan-code-activate",
    slug: "plan-code-activate",
    icon: "AwardIcon"
  }
];
