<template>
  <div class="relative">
    <div class="vx-navbar-wrapper nav-menu-wrapper">
      <vs-navbar
        class="vx-navbar navbar-custom navbar-skelton"
        :color="navbarColor"
      >
        <ul class="menu-items flex flex-wrap w-full items-center">
          <li
            class="menu-item"
            v-for="(item, index) in navMenuItems"
            :key="index"
            :class="{ 'mr-2': !(navMenuItems.length === index + 1) }"
          >
            <!-- If header -->
            <template v-if="item.header">
              <h-nav-menu-header :header="item" class="menu-header relative" />
            </template>

            <!-- If it's group -->
            <template v-else-if="item.submenu">
              <h-nav-menu-group
                class="menu-group relative py-4"
                bottom
                :key="`group-${index}`"
                :group="item"
                :groupIndex="index"
                :open="checkGrpChildrenActive(item)"
              />
            </template>

            <!-- If it's link -->
            <div v-else-if="item.url" class="menu-link">
              <h-nav-menu-item
                class="relative py-4 cursor-pointer"
                :to="item.slug !== 'external' ? item.url : null"
                :href="item.slug === 'external' ? item.url : null"
                :icon="item.icon"
                :target="item.target"
                :isDisabled="item.isDisabled"
                :slug="item.slug"
              >
                <span class="truncate">{{ $l.get(item.name) }}</span>
                <vs-chip :color="item.tagColor" v-if="item.tag">{{
                  item.tag
                }}</vs-chip>
              </h-nav-menu-item>
            </div>
          </li>
        </ul>
        <vs-dropdown
          vs-custom-content
          vs-trigger-click
          style="cursor: pointer;"
          v-if="session && session.user"
        >
          <a class="a-icon" href.prevent>
            <vs-row style="width: 170px">
              <vs-col vs-w="2" style="margin-top: 4px">
                <div class="thumb-wrapper">
                  <img :src="getThumb(session.user.name)" class="thumb" />
                </div>
              </vs-col>
              <vs-col vs-w="10" style="text-align: center; margin: auto">
                {{ session.user.name }}
                <vs-icon class icon="expand_more"></vs-icon>
              </vs-col>
            </vs-row>
          </a>

          <vs-dropdown-menu style="width: 200px">
            <vs-dropdown-item @click="onSelectUser(session.credential.user)">
              <vs-row>
                <vs-col vs-w="2" style="margin-top: 4px">
                  <div class="thumb-wrapper">
                    <img
                      :src="getThumb(session.credential.user.name)"
                      class="thumb"
                    />
                  </div>
                </vs-col>
                <vs-col vs-w="10" style="text-align: center; margin: auto">{{
                  session.credential.user.name
                }}</vs-col>
              </vs-row>
            </vs-dropdown-item>
            <vs-dropdown-item
              v-for="(entry, index) in session.credential.user.children"
              :key="index"
              @click="onSelectUser(entry.child)"
            >
              <vs-row>
                <vs-col vs-w="2" style="margin-top: 4px">
                  <div class="thumb-wrapper">
                    <img :src="getThumb(entry.child.name)" class="thumb" />
                  </div>
                </vs-col>
                <vs-col vs-w="10" style="text-align: center; margin: auto">{{
                  entry.child.name
                }}</vs-col>
              </vs-row>
            </vs-dropdown-item>
            <vs-dropdown-item divider @click="isNewUserNodePromptOpen = true">
              <vs-row>
                <vs-col vs-w="2" style="text-align: center; margin-top: 5px;">
                  <feather-icon
                    icon="PlusIcon"
                    class="animated infinite pulse"
                    svgClasses="text-success h-6 w-6"
                  />
                </vs-col>
                <vs-col vs-w="10" style="text-align: center; margin: auto">{{
                  $l.get("user-children.add")
                }}</vs-col>
              </vs-row>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </vs-navbar>
    </div>
    <vs-prompt
      @cancel="fullName = ''"
      @accept="onNewUserNodeSubmit"
      @close="fullName = ''"
      :accept-text="$l.get('dictionary.submit')"
      :cancel-text="$l.get('dictionary.cancel')"
      :active.sync="isNewUserNodePromptOpen"
      :title="$l.get('user-children.add')"
      :buttonsHidden="true"
    >
      <Loader v-if="isCreatingUserNode" />
      <div class="con-prompt" v-else>
        <vs-input
          :label-placeholder="$l.get('dictionary.full-name', 'capitalize')"
          :danger="isFullNameInvalid"
          :danger-text="$l.get('errors.input-required')"
          v-model="fullName"
        />
        <vs-button
          class="mt-2"
          :disabled="fullName.length < 1"
          @click="onNewUserNodeSubmit"
          >{{ $l.get("dictionary.submit") }}</vs-button
        >
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import HNavMenuGroup from "./HorizontalNavMenuGroup.vue";
import HNavMenuHeader from "./HorizontalNavMenuHeader.vue";
import HNavMenuItem from "./HorizontalNavMenuItem.vue";

import CreateUserChildrenInteractor from "@/business/nav-bar/create-user-children";
import SelectUserInteractor from "@/business/nav-bar/select-user";
import Loader from "@/application/components/Loader";

export default {
  props: {
    // navbarColor  : { type: String, default: "#fff", },
    navMenuItems: { type: Array, required: true }
  },
  components: {
    HNavMenuGroup,
    HNavMenuHeader,
    HNavMenuItem,
    Loader
  },
  data() {
    const sessionGateway = this.$injector.get("SessionGateway");
    const session = sessionGateway.get();
    return {
      interactors: {
        createUserChildren: null,
        selectUser: null
      },
      session,
      isNewUserNodePromptOpen: false,
      isCreatingUserNode: false,
      fullName: "",
      isFullNameInvalid: false
    };
  },
  beforeMount() {
    this.interactors.createUserChildren = this.$injector.get(
      CreateUserChildrenInteractor
    );
    this.interactors.selectUser = this.$injector.get(SelectUserInteractor);
  },
  computed: {
    navbarColor() {
      return this.$store.state.theme === "dark" ? "#10163a" : "#fff";
    }
  },
  methods: {
    getThumb(name) {
      const char = name.charAt(0).toUpperCase();
      return `https://via.placeholder.com/64/5B3CC4/FFFFFF/?text=${char}`;
    },
    onNewUserNodeSubmit() {
      this.isFullNameInvalid = this.fullName.length < 1;
      if (this.isFullNameInvalid) return;

      this.isCreatingUserNode = true;
      const _ = this;
      this.interactors.createUserChildren.handle(this.fullName).then(() => {
        _.fullName = "";
        _.isCreatingUserNode = false;
        _.isNewUserNodePromptOpen = false;
      });
    },
    onSelectUser(user) {
      this.interactors.selectUser.handle(user);
    },
    checkGrpChildrenActive(group) {
      const path = this.$route.fullPath;
      let active = false;
      const routeParent = this.$route.meta
        ? this.$route.meta.parent
        : undefined;

      if (group.submenu) {
        group.submenu.forEach(item => {
          if (active) return true;
          if ((path == item.url || routeParent == item.slug) && item.url) {
            active = true;
          } else if (item.submenu) {
            this.checkGrpChildrenActive(item);
          }
        });
      }

      return active;
    }
  }
};
</script>

<style lang="scss">
@import "@/application/assets/scss/vuexy/components/horizontalNavMenu.scss";
</style>

<style lang="scss" scoped>
.thumb-wrapper {
  display: inline-block;
  overflow: hidden;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.thumb {
  width: 100%;
  height: 100%;
  transform: translateX(0px) translateY(-1px) scale(2);
  object-position: center;
}

.con-prompt .vs-con-input-label {
  width: 100% !important;
}
.con-prompt .vs-button {
  width: 100% !important;
}
</style>
