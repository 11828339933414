<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="relative">
    <div class="vx-navbar-wrapper navbar-full p-0">
      <vs-navbar
        class="navbar-custom navbar-skelton"
        :class="navbarClasses"
        :style="navbarStyle"
        :color="navbarColor"
      >
        <!-- <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" /> -->
        <p class="text-gray" style="font-size: 9px">
          {{ version }}
          <b>BETA</b>
        </p>
        <div class="vx-logo mx-auto flex items-center">
          <img
            src="@/application/assets/images/logo/dnaskills.png"
            height="50"
            style="margin-top: -7px; margin-bottom: -7px;"
          />
          <span class="vx-logo-text"> <b>dna</b>skills </span>
        </div>
        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
          <span
            class="cursor-pointer flex items-center i18n-locale"
            v-if="$l.isPtBr"
          >
            <img
              class="h-4 w-5"
              src="@/application/assets/images/graphics/brazil.png"
              alt="pt-BR"
            />
            <span class="hidden sm:block ml-2"
              >&nbsp;{{ $l.get("dictionary.languages.portuguese") }}</span
            >
          </span>
          <span
            class="cursor-pointer flex items-center i18n-locale"
            v-if="$l.isEnUs"
          >
            <img
              class="h-4 w-5"
              src="@/application/assets/images/graphics/EUA.png"
              alt="en-US"
            />
            <span class="hidden sm:block ml-2"
              >&nbsp;{{ $l.get("dictionary.languages.english") }}</span
            >
          </span>
          <span
            class="cursor-pointer flex items-center i18n-locale"
            v-if="$l.isEsEs"
          >
            <img
              class="h-4 w-5"
              src="@/application/assets/images/graphics/spain.png"
              alt="es-ES"
            />
            <span class="hidden sm:block ml-2"
              >&nbsp;{{ $l.get("dictionary.languages.spanish") }}</span
            >
          </span>
          <vs-dropdown-menu class="w-48 i18n-dropdown vx-navbar-dropdown">
            <vs-dropdown-item @click="updateLocale('pt-BR')">
              <img
                class="h-4 w-5"
                src="@/application/assets/images/graphics/brazil.png"
                alt="pt-BR"
                style="margin-right: 5px;"
              />
              {{ $l.get("dictionary.languages.portuguese") }}
            </vs-dropdown-item>
            <vs-dropdown-item @click="updateLocale('en-US')">
              <img
                class="h-4 w-5"
                src="@/application/assets/images/graphics/EUA.png"
                alt="pt-BR"
                style="margin-right: 5px;"
              />
              {{ $l.get("dictionary.languages.english") }}
            </vs-dropdown-item>
            <vs-dropdown-item @click="updateLocale('es-ES')">
              <img
                class="h-4 w-5"
                src="@/application/assets/images/graphics/spain.png"
                alt="pt-BR"
                style="margin-right: 5px;"
              />
              {{ $l.get("dictionary.languages.spanish") }}
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div style="margin-left: 10px">
          <vs-button
            type="border"
            icon="icon-log-out"
            icon-pack="feather"
            color="danger"
            size="small"
            @click="onLogout"
          ></vs-button>
        </div>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import SelectLogoutInteractor from "@/business/nav-bar/select-logout";
import SelectLanguageInteractor from "@/business/nav-bar/select-language";
import { version } from "@/../package.json";

export default {
  name: "the-navbar-horizontal",
  props: {
    logo: { type: String },
    navbarType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      interactors: {
        selectLogout: null,
        selectLanguage: null
      },
      version
    };
  },
  beforeMount() {
    this.interactors.selectLogout = this.$injector.get(SelectLogoutInteractor);
    this.interactors.selectLanguage = this.$injector.get(
      SelectLanguageInteractor
    );
  },
  computed: {
    navbarColor() {
      let color = "#fff";
      if (this.navbarType === "sticky") color = "#f7f7f7";
      else if (this.navbarType === "static") {
        if (this.scrollY < 50) {
          color = "#f7f7f7";
        }
      }

      this.isThemedark === "dark"
        ? color === "#fff"
          ? (color = "#10163a")
          : (color = "#262c49")
        : null;

      return color;
    },
    isThemedark() {
      return this.$store.state.theme;
    },
    navbarStyle() {
      return this.navbarType === "static"
        ? { transition: "all .25s ease-in-out" }
        : {};
    },
    navbarClasses() {
      return this.scrollY > 5 && this.navbarType === "static"
        ? null
        : "d-theme-dark-light-bg shadow-none";
    },
    scrollY() {
      return this.$store.state.scrollY;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  },
  methods: {
    onLogout() {
      this.interactors.selectLogout.handle();
    },
    updateLocale(locale) {
      this.interactors.selectLanguage.handle(locale);
    }
  }
};
</script>
